import React, { useRef, useState, useEffect } from "react";
import { useResource, useRender, useThree } from "react-three-fiber";
import { TweenMax, TimelineMax } from "gsap";
import { Vector3 } from "three";

const Image = ({
  image,
  hover,
  active,
  activeImage,
  activeCoords,
  mesh,
  envmap,
}) => {
  const imgRef = useRef();
  const firstUpdate = useRef(true);

  const imgColor = "#000000";

  useEffect(() => {
    if (active) {
      return;
    }

    if (hover) {
      TweenMax.to(imgRef.current.color, 1, { r: 0.2, g: 0.2, b: 0.2 });
    } else {
      TweenMax.to(imgRef.current.color, 1, { r: 1, g: 1, b: 1 });
    }
  }, [hover]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const animation = new TimelineMax();
    animation.to(imgRef.current.color, 0.375, { r: 0, g: 0, b: 0 });
    animation.call(() => {
      if (active) {
        imgRef.current.map = activeImage.clone();
        imgRef.current.map.needsUpdate = true;
        imgRef.current.map.repeat.set(1 / 3, 1 / 3);

        imgRef.current.map.center.x = activeCoords[0];
        imgRef.current.map.center.y = activeCoords[1];
        TweenMax.to(mesh.current.scale, 0.5, { x: 1 + 1 / 3, y: 1 + 1 / 3 });
      } else {
        imgRef.current.map = image;
        TweenMax.to(mesh.current.scale, 0.5, { x: 1, y: 1 });
      }
    });
    animation.to(imgRef.current.color, 0.375, { r: 1, g: 1, b: 1 });

    animation.play(0);
  }, [active]);

  const color = "grey";
  return (
    <>
      <meshStandardMaterial
        attachArray="material"
        color={color}
        envMap={envmap}
        roughness={0.05}
        metalness={1}
        envMapIntensity={1}
      />
      <meshStandardMaterial
        attachArray="material"
        color={color}
        envMap={envmap}
        roughness={0.05}
        metalness={1}
        envMapIntensity={1}
      />
      <meshStandardMaterial
        attachArray="material"
        color={color}
        envMap={envmap}
        roughness={0.05}
        metalness={1}
        envMapIntensity={1}
      />
      <meshStandardMaterial
        attachArray="material"
        color={color}
        envMap={envmap}
        roughness={0.05}
        metalness={1}
        envMapIntensity={1}
      />
      <meshBasicMaterial
        ref={imgRef}
        attachArray="material"
        map={image}
        color={imgColor}
      />
      <meshLambertMaterial attachArray="material" color={color} />
    </>
  );
};

const Shape = ({
  position,
  image,
  index,
  hidden,
  active,
  activeImage = null,
  activeCoords = null,
  name,
  envmap,
}) => {
  const meshRef = useRef();
  const [hover, setHover] = useState(false);
  const [geometryRef] = useResource();
  const { camera, canvas } = useThree();

  useEffect(() => {
    if (active) {
      TweenMax.to(`#${name}`, 0.5, { autoAlpha: 0 });
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      return;
    }

    if (hover) {
      TweenMax.to(`#${name}`, 1, { autoAlpha: 1 });
    } else {
      TweenMax.to(`#${name}`, 1, { autoAlpha: 0 });
    }
  }, [hover]);

  useRender(() => {
    if (geometryRef.current !== null) {
      const mesh = geometryRef.current.parent;
      mesh.updateWorldMatrix(true, false);
      const vector = geometryRef.current.vertices[2].clone();
      const vector2 = geometryRef.current.vertices[7].clone();
      const middleVector = vector.add(vector2).divideScalar(2);
      middleVector.add(new Vector3(0, 1, 0));
      middleVector.applyMatrix4(mesh.matrixWorld);
      middleVector.project(camera);
      const x = (middleVector.x * 0.5 + 0.5) * canvas.clientWidth;
      const y = (middleVector.y * -0.5 + 0.5) * canvas.clientHeight;
      // TweenMax.set(nameRef.current, { x: '-50%', y: '-50%' });
      // TweenMax.set(nameRef.current, { x, y });
      // GSAP will not work here for whatever reason, if it aint broke don't fix it!
      const elem = document.querySelector(`#${name}`);
      elem.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
    }
  });

  return (
    <group>
      <mesh
        ref={meshRef}
        position={position}
        userData={{
          toggleHover(bool) {
            setHover(bool);
          },
          tenantIndex: index,
        }}
        castShadow
        receiveShadow
      >
        <boxGeometry attach="geometry" ref={geometryRef} args={[6, 6, 2000]} />
        <Image
          image={image}
          hover={hover}
          hidden={hidden}
          active={active}
          activeImage={activeImage}
          activeCoords={activeCoords}
          mesh={meshRef}
          envmap={envmap}
        />
      </mesh>
      {/* {geometry && (
        <lineSegments position={position}>
          <edgesGeometry attach="geometry" args={[geometry.clone()]} />
          <lineBasicMaterial attach="material" color="#4C4C4C" />
        </lineSegments>
      )} */}
    </group>
  );
};

export default Shape;
