import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CellWrapper from "src/components/cellWrapper.js";
import { TweenMax } from "gsap";
import Img from "gatsby-image";

import StaticGallery from "src/components/staticGallery";

import Right from "src/images/chevrons/right-chevron.png";
import Left from "src/images/chevrons/left-chevron.png";

import IG from "src/images/social/instagram.png";
import TWITTER from "src/images/social/twitter.png";
import WEBSITE from "src/images/social/website.png";
import copy from "src/copy";

const Viewport = styled.div`
  height: 0px;
  overscroll-behavior-y: contain;
`;

const FullImageContainer = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 400;
  border: 0.25px solid #000;
`;

const FullImageButton = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 350;
  pointer-events: auto;
  display: ${props => (props.imageOpen ? `auto` : `none`)};
`;

const FullImageWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FullImageBack = styled.button`
  font-family: "URWAccidalia";
  font-size: 14px;
  background: none;
  margin: 0;
  color: white;
  border: none;
  margin-bottom: 20px;
  pointer-events: ${props => (props.imageOpen ? `auto` : `none`)};
  @media (max-width: 500px) {
    font-size: 14px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const FullImageCarousel = styled.div`
  display: grid;
  grid-template-columns: 10% 80% 10%;
  width: 400px;
  z-index: 500;
  justify-content: center;
  @media (max-width: 500px) {
    width: 400px;
    grid-template-columns: 10% 80% 10%;
  }
  @media (max-width: 380px) {
    width: 360px;
    grid-template-columns: 10% 80% 10%;
  }
  @media (max-width: 330px) {
    width: 300px;
    grid-template-columns: 10% 80% 10%;
  }
`;

const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  pointer-events: ${props => (props.imageOpen ? `auto` : `none`)};
`;

const Chevron = styled.img`
  width: 24px;
  z-index: 500;
  pointer-events: auto;
`;

const FullImage = styled(Img)`
  width: 300px;
  pointer-events: none;
  z-index: 400;
  justify-self: center;
  @media (max-width: 500px) {
    width: 305px;
  }
  @media (max-width: 380px) {
    width: 275px;
  }
  @media (max-width: 330px) {
    width: 225px;
  }
`;

const Mask = styled.div`
  transition: all 0.5s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: 0;
  padding: 0;
  z-index: 200;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  opacity: ${props => (props.tenantsView ? "1" : "0")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ContentContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: black;
  margin: 0;
  padding: 0;
  z-index: 100;
  border: 0.25px solid #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TenantMask = styled.div`
  position: absolute;
  padding: 25px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "URWAccidalia";
  color: white;

  margin: -10px 0 0 0;

  @media (max-height: 500px) {
    margin: 0 0 0 0;
  }
`;

const TenantMaskButton = styled.div`
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 350;
  pointer-events: auto;
`;

const TenantInfoSocial = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.hasWebsite ? "50% 50%" : "1fr")};
  margin-top: 5px;
  width: 100%;
  z-index: 400;
  @media (min-height: 550px) {
    margin-top: 15px;
  }
  @media (min-height: 600px) {
    margin-top: 20px;
  }
`;

const TenantInfoSocialWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  text-align: center;
  background: black;
  z-index: 400;
  pointer-events: auto;

  h3 {
    transition: color 0.3s ease;
    margin: 0;
    font-family: "URWAccidalia";
    font-size: 8px;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
  }
`;

const TenantInfoSocialIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 90%;
  background: black;
  margin-bottom: 5px;

  a {
    margin: 0;
    font-family: "URWAccidalia";
    font-size: 8px;
    text-decoration: none;
    color: white;
    margin-left: 3px;
    padding-top: 2px;
  }

  img {
    height: 12px;
  }
`;

const TenantNameHireContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TenantName = styled.h1`
  font-size: 20px;
  font-family: "URWAccidalia";
  text-transform: uppercase;
  @media (max-width: 330px) {
    font-size: 18px;
  }
`;

const Hire = styled.a`
  color: white;
  font-size: 14px;
  font-family: "URWAccidalia";
  background-color: black;
  border: 1px solid white;
  padding: 8px;
  margin: 0;
  pointer-events: auto;
  z-index: 400;
  @media (max-width: 500px) {
    font-size: 12px;
    padding: 6px;
  }
  @media (max-width: 330px) {
    font-size: 10px;
    padding: 5px;
  }
`;

const TenantSubtitle = styled.p`
  color: white;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 5px;
  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
  @media (max-width: 330px) {
    font-size: 10px;
  }
  @media (min-height: 600px) {
    margin-bottom: 15px;
  }
`;

const TenantDescription = styled.p`
  font-family: "Univers";
  font-size: 12px;
  line-height: 1.2;
  @media (max-width: 375px) {
    font-size: 12px;
  }
  @media (max-width: 330px) {
    font-size: 9px;
  }
`;

const Back = styled.button`
  font-family: "URWAccidalia";
  color: white;
  font-size: 12px;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin-bottom: 20px;
  pointer-events: auto;
  z-index: 400;

  @media (max-width: 400px) {
    margin-bottom: 15px;
  }

  @media (max-width: 330px) {
    font-size: 10px;
    margin-bottom: 10px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const GalleryWrapper = styled.div`
  margin-top: 10px;
  z-index: 999;
  @media (min-height: 600px) {
    margin-top: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  overflow: hidden;
`;

const Scene = styled.div`
  width: 335px;
  height: 465px;
  position: fixed;
  perspective: 400px;
`;

const { tenants } = copy;

const TenantMobile = ({ deepLinkedTenant, data }) => {
  //Refs to track what is in the middle and scroll angles
  const middle = useRef(0);
  const noScroll = useRef();
  const currAngleRef = useRef();

  //states used to track current angle and page view
  const [tenantsView, setTenantsView] = useState(true);
  const [tenantName, setTenantName] = useState("");
  const [currAngle, setCurrAngle] = useState(middle.current);
  const [tenantBackground, setTenantBackground] = useState("");
  const [currentTenant, setTenantIndex] = useState(0);
  const [fullImage, setFullImage] = useState(0);

  //change states to change view function
  const changeView = (name, angle = 2480, index) => {
    if (name) {
      window.history.pushState("", "", `/tenants/${name.toLowerCase()}`);
      setTenantsView(false);
      setTenantIndex(index);
    } else {
      window.history.pushState("", "", `/tenants`);
      setTenantsView(true);
    }
    if (angle !== 2480) {
      setCurrAngle(angle);
    }
  };

  //Track current angle when going between carousel vs tenant profile
  useEffect(() => {
    currAngleRef.current = currAngle;
  }, [currAngle]);

  //get middle of page and no scroll function to stay at current angle
  useEffect(() => {
    middle.current = document.body.scrollHeight / 2 - window.innerHeight / 2;
    // window.scrollTo(0, middle.current);

    const noScrollFunc = () => {
      window.scrollTo(0, currAngleRef.current);
    };

    noScroll.current = noScrollFunc;
  }, []);

  //switch between views based on state and turn on or off no scroll function
  useEffect(() => {
    if (!tenantsView) {
      TweenMax.to(`#tenantInfo`, 0.5, { autoAlpha: 1 });
      window.addEventListener("scroll", noScroll.current);
    } else {
      TweenMax.to(`#tenantInfo`, 0.5, { autoAlpha: 0 });
      window.removeEventListener("scroll", noScroll.current);
    }
    return () => {
      window.removeEventListener("scroll", noScroll.current);
    };
  });

  //states used to move through tenant portfolio work
  const [imageSrc, setImageSrc] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  //function to change image shown in lightbox and go to lightbox
  const changeImage = src => {
    setImageOpen(true);
    setImageSrc(src);
    TweenMax.to("#fullImageContainer", 0.5, { autoAlpha: 1 });
  };
  //function to go back to tenant portfolio
  const hideImg = () => {
    setImageOpen(false);
    TweenMax.to("#fullImageContainer", 0.5, { autoAlpha: 0 });
  };
  //show image left of previous image unless at first image
  const leftImage = () => {
    const numberOfElement =
      data[tenants[currentTenant].imageRef].edges.length - 1;

    if (fullImage !== 0) {
      setFullImage(prev => prev - 1);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[fullImage - 1].node.large
          .fluid
      );
    } else {
      setFullImage(numberOfElement);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[numberOfElement].node.large
          .fluid
      );
    }
  };
  //show image right of previous image unless at last image
  const rightImage = () => {
    const numberOfElement =
      data[tenants[currentTenant].imageRef].edges.length - 1;

    if (fullImage !== numberOfElement) {
      setFullImage(prev => prev + 1);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[fullImage + 1].node.large
          .fluid
      );
    } else {
      setFullImage(0);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[0].node.large.fluid
      );
    }
  };

  return (
    <>
      <FullImageButton
        imageOpen={imageOpen}
        onClick={hideImg}
      ></FullImageButton>
      <FullImageContainer id="fullImageContainer">
        <FullImageWrapper>
          <FullImageBack onClick={hideImg} imageOpen={imageOpen}>
            BACK
          </FullImageBack>
          <FullImageCarousel>
            <ChevronWrapper imageOpen={imageOpen} onClick={leftImage}>
              <Chevron src={Left}></Chevron>
            </ChevronWrapper>
            <FullImage
              className="tweenImg"
              fluid={imageSrc || data.erikaWar.edges[0].node.small.fluid}
            />
            <ChevronWrapper imageOpen={imageOpen} onClick={rightImage}>
              <Chevron src={Right}></Chevron>
            </ChevronWrapper>
          </FullImageCarousel>
        </FullImageWrapper>
      </FullImageContainer>
      <Mask tenantsView={tenantsView}></Mask>
      <ContentContainer id="tenantInfo" tenantsView={tenantsView}>
        <TenantMaskButton onClick={() => changeView()}></TenantMaskButton>
        <TenantMask>
          {!tenantsView && (
            <>
              <Back onClick={() => changeView()}>BACK</Back>
              <TenantNameHireContainer>
                <TenantName>{tenants[currentTenant].name} &ndash;</TenantName>
                <Hire
                  href={`mailto:hire@hxouse.com?subject=Hire ${tenants[currentTenant].name}`}
                >
                  HIRE
                </Hire>
              </TenantNameHireContainer>
              <TenantSubtitle>{tenants[currentTenant].title}</TenantSubtitle>
              <TenantDescription>
                {tenants[currentTenant].description}
              </TenantDescription>
              {data[tenants[currentTenant].imageRef].edges.length !== 0 ? (
                <GalleryWrapper>
                  <StaticGallery
                    setFullImage={setFullImage}
                    data={data}
                    imageRef={tenants[currentTenant].imageRef}
                    changeImage={changeImage}
                  />
                </GalleryWrapper>
              ) : null}
              {tenants[currentTenant].website !== "" ? (
                <TenantInfoSocial
                  hasWebsite={tenants[currentTenant].website !== ""}
                >
                  <TenantInfoSocialWrapper
                    style={{
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    href={`http://${tenants[currentTenant].website}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>WEBSITE</h3>
                    <TenantInfoSocialIconWrapper>
                      <img src={WEBSITE}></img>
                      <a>W</a>
                    </TenantInfoSocialIconWrapper>
                  </TenantInfoSocialWrapper>
                  <TenantInfoSocialWrapper
                    style={{
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    }}
                    href={`http://www.instagram.com/${tenants[currentTenant].ig}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>INSTAGRAM</h3>
                    <TenantInfoSocialIconWrapper>
                      <img src={IG}></img>
                      <a>01</a>
                    </TenantInfoSocialIconWrapper>
                  </TenantInfoSocialWrapper>
                </TenantInfoSocial>
              ) : (
                <TenantInfoSocial
                  hasWebsite={tenants[currentTenant].website !== ""}
                >
                  <TenantInfoSocialWrapper
                    style={{
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                    href={`http://www.instagram.com/${tenants[currentTenant].ig}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h3>INSTAGRAM</h3>
                    <TenantInfoSocialIconWrapper>
                      <img src={IG}></img>
                      <a>01</a>
                    </TenantInfoSocialIconWrapper>
                  </TenantInfoSocialWrapper>
                </TenantInfoSocial>
              )}
            </>
          )}
        </TenantMask>
      </ContentContainer>
      <Viewport id="viewport">
        <Container>
          <Scene>
            <CellWrapper
              deepLinkedTenant={deepLinkedTenant}
              data={data.displayPictures.edges}
              numOfCells={18}
              cellSize={465}
              tenants={copy.tenants}
              changeView={changeView}
              currAngle={currAngle}
              tenantsView={tenantsView}
            />
          </Scene>
        </Container>
      </Viewport>
    </>
  );
};

export default TenantMobile;
