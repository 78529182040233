import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Cell from "src/components/cell.js";
import { TweenMax } from "gsap";

import copy from "src/copy";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transform: ${props => `translateZ(-1500px) rotateX(2480.25deg)`};
  overscroll-behavior-y: contain;
`;

const CellWrapper = ({
  deepLinkedTenant,
  data,
  cellSize,
  numOfCells,
  tenants,
  changeView,
  currAngle,
  tenantsView,
}) => {
  //Translation Z required to make the carousel round
  const TZ = Math.round(cellSize / 2 / Math.tan(Math.PI / numOfCells));

  //Setup required angles for carousel panels based on number of items
  const rotationAngles = [];
  const angle = 360 / numOfCells;
  for (let i = 0; i <= 360 - angle; i += angle) {
    rotationAngles.push(i);
  }

  //setup handleScroll function reference
  const handleScroll = useRef();
  const handleTouch = useRef();
  const handleTouchStart = useRef();
  const handleTouchEnd = useRef();
  const handleInertia = useRef();
  const startPos = useRef({ y: 0, time: 0 });
  const endPos = useRef({ y: 0, time: 0 });
  const inertia = useRef(0);
  const deepLinkedTenantIndex = useRef();

  const oldScroll = useRef(0);
  useEffect(() => {
    //Relates scroll of page to function of carousel
    const handleScrollFunc = () => {
      const scrollTotal = document.body.scrollHeight - window.innerHeight;
      const scrollPosY = window.scrollY;
      const posPercent = Math.abs(scrollPosY / scrollTotal);

      TweenMax.set("#wrapper", {
        rotationX: `+=${(oldScroll.current - scrollPosY) * 0.05}`,
      });

      oldScroll.current = scrollPosY;

      if (posPercent >= 1) {
        window.scroll(
          0,
          document.body.scrollHeight / 2 - window.innerHeight / 2
        );
        oldScroll.current =
          document.body.scrollHeight / 2 - window.innerHeight / 2;
      }
      if (posPercent <= 0) {
        window.scroll(
          0,
          document.body.scrollHeight / 2 - window.innerHeight / 2
        );
        oldScroll.current =
          document.body.scrollHeight / 2 - window.innerHeight / 2;
      }
    };

    const handleTouchFunc = e => {
      e.preventDefault();
      TweenMax.set("#wrapper", {
        rotationX: `+=${(oldScroll.current - e.changedTouches[0].screenY) *
          0.1}`,
      });
      oldScroll.current = e.changedTouches[0].screenY;
    };
    const handleTouchStartFunc = e => {
      oldScroll.current = e.changedTouches[0].screenY;
      inertia.current = 0;
      startPos.current = {
        y: e.changedTouches[0].screenY,
        time: Date.now(),
      };
    };

    const handleTouchEndFunc = e => {
      endPos.current = {
        y: e.changedTouches[0].screenY,
        time: Date.now(),
      };
      const distance = endPos.current.y - startPos.current.y;
      const time = (endPos.current.time - startPos.current.time) / 1000;
      const velocity = distance / time;
      if (Math.abs(velocity) < 800) {
        inertia.current = 0;
      } else {
        inertia.current = velocity;
      }
      // console.log(distance, time, velocity);
    };

    const animateInertia = () => {
      TweenMax.set("#wrapper", {
        rotationX: `+=${-inertia.current * 0.01}`,
        overwrite: false,
      });
      // console.log(inertia.current);
      inertia.current = +(inertia.current * 0.8).toFixed(2);
      window.requestAnimationFrame(animateInertia);
    };

    handleScroll.current = handleScrollFunc;
    handleTouch.current = handleTouchFunc;
    handleTouchStart.current = handleTouchStartFunc;
    handleTouchEnd.current = handleTouchEndFunc;
    handleInertia.current = animateInertia;

    let rotateTo;

    if (deepLinkedTenant) {
      for (let i = 0; i < copy.tenants.length; i++) {
        if (
          copy.tenants[i].name.toLowerCase() === deepLinkedTenant.toLowerCase()
        ) {
          deepLinkedTenantIndex.current = i;
        }
      }
    }
    if (typeof deepLinkedTenantIndex.current === "number") {
      rotateTo = -angle * (deepLinkedTenantIndex.current - 2);
      TweenMax.to("#wrapper", 2.5, {
        rotationX: `+=${rotateTo}`,
        onComplete: () => {
          changeView(
            copy.tenants[deepLinkedTenantIndex.current].name,
            window.scrollY,
            deepLinkedTenantIndex.current
          );
        },
      });
    } else {
      rotateTo =
        angle * (numOfCells + Math.floor(Math.random() * (numOfCells - 1)) + 1);
      TweenMax.to("#wrapper", 2.5, {
        rotationX: `+=${rotateTo}`,
      });
    }
  }, []);

  //If in carousel view, start scroll function
  useEffect(() => {
    if (tenantsView) {
      if (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      ) {
        window.addEventListener("touchstart", handleTouchStart.current);
        window.addEventListener("touchend", handleTouchEnd.current);
        window.addEventListener("touchmove", handleTouch.current, {
          passive: false,
        });

        window.requestAnimationFrame(handleInertia.current);
      }
    }

    return () => {
      window.removeEventListener("touchstart", handleTouchStart.current);
      window.removeEventListener("touchmove", handleTouch.current);
      window.cancelAnimationFrame(handleInertia.current);
    };
  }, [tenantsView]);

  //Function to change from carousel view to a tenant profile
  const changeViewHelper = (name, tenantIndex) => {
    changeView(name, window.scrollY, tenantIndex);
  };
  return (
    <Wrapper TZ={TZ} id="wrapper" currAngle={currAngle}>
      {rotationAngles.map((angle, i) => (
        <Cell
          angle={angle}
          TZ={TZ}
          data={data[i].node.fixed.fixed}
          name={tenants[i].name}
          image={tenants[i].image}
          changeView={changeViewHelper}
          tenantIndex={i}
          key={i}
        />
      ))}
      ;
    </Wrapper>
  );
};

export default CellWrapper;
