import React, { useContext, useRef, useEffect } from "react";
import { graphql } from "gatsby";

import TenantMobile from "src/components/tenantMobile";
import TenantDesktop from "src/components/tenantDesktop";
import { ScrollHintContext } from "src/contexts/scrollHintContext";
import useWindowSize from "src/hooks/useWindowSize";
import "../components/tlwrapper.css";

import SEO from "src/components/seo.js";

const Tenants = ({ location, data }) => {
  const { width } = useWindowSize();
  const { hasAnimated, changeHasAnimated } = useContext(ScrollHintContext);
  const deepLinkedTenant = location.pathname.split("/")
    ? location.pathname.split("/")[2]
    : null;

  return (
    <>
      <SEO title="HXOUSE · Tenants" />
      {width < 770 ? (
        <TenantMobile deepLinkedTenant={deepLinkedTenant} data={data} />
      ) : (
        <TenantDesktop
          deepLinkedTenant={deepLinkedTenant}
          data={data}
          hasAnimated={hasAnimated}
          changeHasAnimated={changeHasAnimated}
        />
      )}
    </>
  );
};

export default Tenants;

export const query = graphql`
  query GalleryQuery {
    # Tenant Display Pictures
    displayPictures: allFile(
      filter: {
        relativePath: { regex: "/tenants/" }
        extension: { regex: "/png|jpg|jpeg/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          fixed: childImageSharp {
            fixed(width: 325, height: 450, cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFixed
            }
          }
          fluid: childImageSharp {
            fluid(cropFocus: CENTER, fit: COVER) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # Andi Bordt
    andiBordt: allFile(
      filter: {
        relativePath: { regex: "/gallery/andi-bordt/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # andrew-hall
    andrewHall: allFile(
      filter: {
        relativePath: { regex: "/gallery/andrew-hall/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # angelica-milash
    angelicaMilash: allFile(
      filter: {
        relativePath: { regex: "/gallery/angelica-milash/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # arthikka-jey
    arthikkaJey: allFile(
      filter: {
        relativePath: { regex: "/gallery/arthikka-jey/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # bianca-lichanner
    biancaLichanner: allFile(
      filter: {
        relativePath: { regex: "/gallery/bianca-lichanner/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # elliot-reid
    teijiReid: allFile(
      filter: {
        relativePath: { regex: "/gallery/teiji-reid/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # erika-war
    erikaWar: allFile(
      filter: {
        relativePath: { regex: "/gallery/erika-war/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # flora-yin
    floraYin: allFile(
      filter: {
        relativePath: { regex: "/gallery/flora-yin/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # hallie-tutkaluk
    hallieTutkaluk: allFile(
      filter: {
        relativePath: { regex: "/gallery/hallie-tutkaluk/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # jeff-feltham
    jeffFeltham: allFile(
      filter: {
        relativePath: { regex: "/gallery/jeff-feltham/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # joey-gollish
    joeyGollish: allFile(
      filter: {
        relativePath: { regex: "/gallery/joey-gollish/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # max-gottlieb
    maxGottlieb: allFile(
      filter: {
        relativePath: { regex: "/gallery/max-gottlieb/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # Michelle Bali
    michelleBali: allFile(
      filter: {
        relativePath: { regex: "/gallery/michelle-bali/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    # mimi-vuong
    mimiVuong: allFile(
      filter: {
        relativePath: { regex: "/gallery/mimi-vuong/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # patricia-roque
    patriciaRoque: allFile(
      filter: {
        relativePath: { regex: "/gallery/patricia-roque/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # rahul-madan
    rahulMadan: allFile(
      filter: {
        relativePath: { regex: "/gallery/rahul-madan/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # saint-amorah
    saintAmorah: allFile(
      filter: {
        relativePath: { regex: "/gallery/saint-amorah/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    # zakiyah-williams
    zakiyahWilliams: allFile(
      filter: {
        relativePath: { regex: "/gallery/zakiyah-williams/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 160
              maxHeight: 160
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`;
