import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Panel = styled.div`
  position: absolute;
  display: flex;
  width: 325px;
  height: 450px;
  left: 5px;
  top: 5px;
  transform: ${props => `rotateX(${props.angle}deg) translateZ(${props.TZ}px)`};
`;

const PrismContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: translateZ(-20px);
`;

const PrismFaceFront = styled.div`
  transform: translateZ(20px);
  z-index: 100;
  transform-style: preserve-3d;
`;

const PrismImgWrapper = styled.div`
  position: absolute;
`;

const Mask = styled.div`
  position: absolute;
  width: 325px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const Name = styled.div`
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  font-size: 45px;
  font-family: "URWAccidalia", sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
`;

const Cell = ({ angle, TZ, name, image, changeView, tenantIndex, data }) => (
  <Panel
    angle={angle}
    TZ={TZ}
    onClick={() => changeView(name, tenantIndex)}
    name={name}
    image={image}
  >
    <PrismContainer>
      <PrismFaceFront image={image}>
        <PrismImgWrapper>
          <Img fixed={data} />
        </PrismImgWrapper>
        <Mask>
          <Name>{name}</Name>
        </Mask>
      </PrismFaceFront>
    </PrismContainer>
  </Panel>
);

export default Cell;
