import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
  useLayoutEffect,
} from "react";
import styled from "styled-components";
import { TweenMax, Power2, Power4 } from "gsap";
import * as THREE from "three";
import { Canvas, useThree, useRender } from "react-three-fiber";
import Img from "gatsby-image";

import copy from "src/copy";
import Shape from "src/components/shape";
import StaticGallery from "src/components/staticGallery";

import cubeSide1 from "src/images/reflection/noblur/mirror/1.png";
import cubeSide2 from "src/images/reflection/noblur/mirror/2.png";

import Right from "src/images/chevrons/right-chevron.png";
import Left from "src/images/chevrons/left-chevron.png";

import useWindowSize from "src/hooks/useWindowSize";
import { navigate } from "gatsby";

const TitleContainer = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  align-items: center;

  /* pointer-events: none; */
`;

const PageName = styled.h1`
  writing-mode: vertical-rl;
  -webkit-text-stroke: 1px white;
  color: transparent;
  font-family: "URWAccidalia", sans-serif;
  font-size: 42pt;
  padding-left: 16px;
  z-index: 1000;
  @media (max-width: 1150px) {
    display: none;
  }
  opacity: 0;
`;

const FullImageContainer = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 400;
  pointer-events: none;
`;

const FullImageButton = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 350;
  pointer-events: auto;
  display: ${props => (props.imageOpen ? `auto` : `none`)};
`;

const FullImageWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 500;
  pointer-events: none;
`;

const FullImageBack = styled.button`
  font-family: "URWAccidalia", sans-serif;
  font-size: 20px;
  pointer-events: auto;
  background: none;
  margin: 0;
  color: white;
  border: none;
  margin-bottom: 20px;
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const FullImageCarousel = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 750px;
  z-index: 500;
  @media (max-width: 700px) {
    width: 600px;
  }
  @media (max-height: 750px) and (min-width: 700px) {
    width: 650px;
  }
  @media (max-height: 600px) and (min-width: 700px) {
    width: 500px;
  }
`;

const FullImage = styled(Img)`
  width: 500px;
  pointer-events: none;
  z-index: 400;
  justify-self: center;
  @media (max-width: 700px) {
    width: 400px;
  }
  @media (max-height: 750px) and (min-width: 700px) {
    width: 450px;
  }
  @media (max-height: 700px) and (min-width: 700px) {
    width: 400px;
  }
  @media (max-height: 600px) and (min-width: 700px) {
    width: 300px;
  }
`;

const ChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  pointer-events: auto;
  &:hover {
    cursor: pointer;
  }
`;

const Chevron = styled.img`
  width: 30px;
  z-index: 500;
  pointer-events: auto;
`;

const canvasStyle = {
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  zIndex: "1",
  background: "rgba(0, 0, 0, 255)",
  visibility: "hidden",
  opacity: 0,
};

const NameHireContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Name = styled.h1`
  color: white;
  font-size: 45px;
  font-family: "URWAccidalia", sans-serif;
  @media (max-height: 650px) {
    font-size: 35px;
  }
  @media (max-height: 600px) {
    font-size: 30px;
  }
  @media (max-height: 520px) {
    font-size: 26px;
  }
`;

const Hire = styled.a`
  color: white;
  font-size: 16px;
  font-family: "URWAccidalia", sans-serif;
  background-color: black;
  border: 1px solid white;
  padding: 10px;
  margin: 0;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: white;
    color: black;
  }

  @media (max-height: 650px) {
    padding: 5px;
  }
  @media (max-height: 600px) {
    font-size: 14px;
  }
  @media (max-height: 520px) {
    font-size: 12px;
  }
`;

const TenantInfoWrapper = styled.div`
  width: 90%;
`;

const BackButton = styled.button`
  margin: 63px 0 20px 0;
  padding: 0;
  font-family: "URWAccidalia", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 700;
  background: none;
  border: none;
  @media (max-width: 1100px) and (min-height: 700px) {
    margin: 120px 0 20px 0;
  }
  @media (min-height: 1000px) {
    margin: 0px 0 20px 0;
  }
  @media (max-height: 600px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
  @media (max-height: 520px) {
    font-size: 12px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const TenantInfo = styled.div`
  box-sizing: border-box;
  padding: 20px 30px 0 40px;
  background-color: black;
  font-family: "URWAccidalia", sans-serif;
  width: 52vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  z-index: 6;

  h2 {
    color: white;
    font-size: 18px;
    margin-top: 5px;
    @media (max-height: 650px) {
      font-size: 16px;
    }
    @media (max-height: 600px) {
      font-size: 14px;
    }
    @media (max-height: 520px) {
      font-size: 12px;
    }
  }

  p {
    width: 100%;
    margin-top: 25px;
    font-family: "Univers";
    line-height: 1.2;
    color: white;
    font-size: 14px;
    text-align: justify;

    @media (max-height: 700px) {
      margin-top: 20px;
      font-size: 13px;
    }
    @media (max-height: 650px) {
      font-size: 12px;
      margin-top: 15px;
    }
    @media (max-height: 600px) {
      font-size: 11px;
      margin-top: 10px;
    }
    @media (max-height: 520px) {
      font-size: 10px;
    }
  }

  h3 {
    font-size: 20px;
    margin-top: 20px;
    font-family: "Univers";
    color: white;
  }

  @media (min-height: 1000px) {
    display: flex;
    align-items: center;
  }
`;

const GalleryContainer = styled.div`
  margin-top: 25px;

  @media (max-height: 700px) {
    margin-top: 15px;
  }
`;

const TenantInfoSocial = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.hasWebsite ? "1fr 1fr" : "1fr")};
  margin-top: 25px;
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;

  @media (max-height: 700px) {
    margin-top: 15px;
  }
`;

const TenantInfoSocialWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-top: none;
  border-bottom: none;
  text-align: center;
  transition: background-color 0.5s ease-in-out;
  border-left: ${props => (props.left ? "none" : null)};
  border-right: ${props => (props.right ? "none" : null)};
  border-radius: ${props =>
    props.left || props.right
      ? props.left && props.right
        ? "8px 8px 8px 8px"
        : props.left
        ? "8px 0 0 8px"
        : "0 8px 8px 0"
      : null};

  &:hover {
    background-color: white;
    h3 {
      color: black;
    }
    p {
      color: black;
    }
  }

  h3 {
    transition: color 0.5s ease-in-out;
    margin: 0;
    font-family: "URWAccidalia";
    font-size: 10px;
    margin-bottom: 7px;
    margin-top: 7px;
    @media (max-height: 600px) {
      font-size: 8px;
    }
    @media (max-height: 520px) {
      font-size: 7px;
    }
  }

  p {
    transition: color 0.5s ease-in-out;
    margin: 0;
    font-family: "Univers";
    font-size: 9px;
    margin-bottom: 7px;
    text-decoration: none;
    color: white;
    text-align: center;
    @media (max-height: 600px) {
      font-size: 7px;
    }
    @media (max-height: 520px) {
      font-size: 6px;
    }
  }
`;

const Names = styled.div`
  position: fixed; /* let us position ourself inside the container */
  left: 0; /* make our position the top left of the container */
  top: 0;
  width: 100vw;
  height: 100vh;
  color: white;
  z-index: 5;

  div {
    position: absolute; /* let us position them inside the container */
    left: 0; /* make their default position the top left of the container */
    top: 0;
    cursor: pointer; /* change the cursor to a hand when over us */
    font-size: large;
    user-select: none; /* don't let the text get selected */
    color: white;
    font-family: "URWAccidalia", sans-serif;
    visibility: hidden;
    opacity: 0;
    font-size: 1.3vw;
    pointer-events: none;
  }
`;

// controls for initial animation
const Controls = ({
  deepLinkedTenant,
  setTenant,
  mouse,
  hasAnimated,
  changeHasAnimated,
}) => {
  const { camera, gl } = useThree();
  const { width, height } = useWindowSize();
  const easingFactor = 0.06;
  const oldPos = useRef({ x: 0, y: 0 });
  const deepLinkedTenantIndex = useRef();

  useEffect(() => {
    TweenMax.set(camera.position, { z: 150 });
    TweenMax.set("#SectionTitle", { y: -200, autoAlpha: 0 });
    TweenMax.to("#SectionTitle", 1.25, {
      y: 0,
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    TweenMax.to("#tenantsCanvas", 1.25, { autoAlpha: 1 });
    gl.setPixelRatio(window.devicePixelRatio);
  }, []);

  useEffect(() => {
    let animateToPos;
    if (width < 800) {
      animateToPos = 35;
    } else if (width < 900) {
      animateToPos = 30;
    } else if (width < 1100) {
      if (height > 1300) {
        animateToPos = 40;
      } else {
        animateToPos = 25;
      }
    } else if (width < 1200) {
      animateToPos = 23;
    } else {
      if (height > 1000) {
        animateToPos = 25;
      } else if (height > 1200) {
        animateToPos = 20;
      } else {
        animateToPos = 20;
      }
    }

    if (hasAnimated === false) {
      if (deepLinkedTenant) {
        for (let i = 0; i < copy.tenants.length; i++) {
          if (
            copy.tenants[i].name.toLowerCase() ===
            deepLinkedTenant.toLowerCase()
          ) {
            deepLinkedTenantIndex.current = i;
          }
        }
      }
      TweenMax.to(camera.position, 1.5, {
        z: animateToPos,
        ease: Power2.easeIn,
        onComplete: () => {
          changeHasAnimated(true);

          if (typeof deepLinkedTenantIndex.current === "number") {
            setTenant(deepLinkedTenantIndex.current);
            TweenMax.to("#info", 0.75, {
              opacity: 1,
            });
          }
        },
      });
    } else {
      TweenMax.set(camera.position, { z: animateToPos });
    }
  }, [width]);

  useRender(
    () => {
      const dx = mouse.x - oldPos.current.x;
      const dy = mouse.y - oldPos.current.y;
      camera.position.x += dx * easingFactor;
      camera.position.y += dy * easingFactor;
      // camera.position.x = -mouse.x;
      // camera.position.y = -mouse.y;

      oldPos.current = { x: camera.position.x, y: camera.position.y };
    },
    false,
    [mouse]
  );

  return null;
};

const Raycaster = ({ setTenant, disable, setMouse }) => {
  const isDisabled = useRef(false);
  const { camera, scene } = useThree();
  let prevObj = null;
  const raycasterRef = useRef(null);

  let meshes;

  useEffect(() => {
    isDisabled.current = disable;
  }, [disable]);

  useEffect(() => {
    const raycaster = new THREE.Raycaster();
    raycasterRef.current = raycaster;

    meshes = scene.children.map(child => {
      if (child.type === "Group" && child.children[0].type === "Mesh") {
        return child.children[0];
      }

      return;
    });

    meshes = meshes.filter(mesh => mesh !== undefined);

    window.addEventListener("click", checkClicked);
    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("click", checkClicked);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  const checkClicked = event => {
    if (isDisabled.current) {
      return;
    }
    const mouse = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    };

    raycasterRef.current.setFromCamera(mouse, camera);

    const intersects = raycasterRef.current.intersectObjects(meshes);

    if (intersects.length) {
      const { object } = intersects[0];
      if (object.type !== "Mesh") {
        return null;
      }
      window.history.pushState(
        "",
        "",
        `/tenants/${tenants[object.userData.tenantIndex].name.toLowerCase()}`
      );
      setTenant(object.userData.tenantIndex);
      document.body.style.cursor = "default";
      setMouse({
        x: 0,
        y: -1.2,
      });
      TweenMax.to("#info", 0.75, { opacity: 1 });
    }
  };

  const onMouseMove = event => {
    if (isDisabled.current) {
      return;
    }
    const mouse = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    };

    raycasterRef.current.setFromCamera(mouse, camera);

    const intersects = raycasterRef.current.intersectObjects(meshes);

    if (intersects.length) {
      const { object } = intersects[0];
      if (object.type !== "Mesh") {
        return null;
      }
      if (prevObj !== null && prevObj !== object) {
        prevObj.userData.toggleHover(false);
      }

      object.userData.toggleHover(true);
      document.body.style.cursor = "pointer";
      prevObj = object;
    } else {
      document.body.style.cursor = "default";
      if (prevObj !== null) {
        prevObj.userData.toggleHover(false);
      }
    }
  };

  return null;
};

let tenants = copy.tenants.map(object => {
  return {
    image: object.image,
    name: object.name.toUpperCase(),
    title: object.title,
    description: object.description,
    ig: object.ig,
    twitter: object.twitter,
    otherSocial: object.otherSocial,
    website: object.website,
    imageRef: object.imageRef,
    texture: undefined,
  };
});

let envmap = undefined;
// generate grid of boxes
const positions = [];
let firstNum = -20;
for (let col = 0; col < 6; col++) {
  let secondNum = -8;
  for (let row = 0; row < 3; row++) {
    positions.push([firstNum, secondNum, -1000]);
    secondNum += 8;
  }
  firstNum += 8;
}

const coordinates = [
  [0, 0],
  [0, 0.5],
  [0, 1],
  [0.5, 0],
  [0.5, 0.5],
  [0.5, 1],
  [1, 0],
  [1, 0.5],
  [1, 1],
];

const TenantDesktop = ({
  deepLinkedTenant,
  data,
  hasAnimated,
  changeHasAnimated,
}) => {
  // for point in the bg
  const [currentTenant, setTenant] = useState(false);
  const currentTenantRef = useRef(currentTenant);
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    tenants.map(object => {
      const loader = new THREE.TextureLoader();
      const texture = loader.load(object.image);

      object.texture = texture;
    });

    if (currentTenant !== false) {
      TweenMax.to("#SectionTitle", 1, { autoAlpha: 0 });
    } else if (currentTenant == false) {
      TweenMax.to("#SectionTitle", 1, { autoAlpha: 1 });
    }

    const loader = new THREE.CubeTextureLoader();
    loader.load(
      [cubeSide1, cubeSide1, cubeSide1, cubeSide1, cubeSide2, cubeSide2],
      map => {
        envmap = map;
        setLoaded(true);
      },
      undefined,
      function(error) {
        console.error(error);
      }
    );

    window.addEventListener("mousemove", onMouseMove, false);
    return () => {
      window.removeEventListener("mousemove", onMouseMove, false);
    };
  }, []);

  useEffect(() => {
    currentTenantRef.current = currentTenant;
  }, [currentTenant]);

  const onMouseMove = event => {
    let mouse;
    if (currentTenantRef.current !== false) {
      return;
    } else if (currentTenantRef.current === false) {
      mouse = {
        x: -((event.clientX / window.innerWidth) * 2 - 1) * 4,
        y: ((event.clientY / window.innerHeight) * 2 + 1) * 2 - 5,
      };
    }

    setMouse(mouse);
  };

  const [imageSrc, setImageSrc] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [fullImage, setFullImage] = useState(0);

  const changeImage = src => {
    setImageOpen(true);
    setImageSrc(src);
    TweenMax.to("#fullImageContainer", 0.5, { autoAlpha: 1 });
  };

  const hideImg = () => {
    setImageOpen(false);
    TweenMax.to("#fullImageContainer", 0.5, { autoAlpha: 0 });
  };

  const leftImage = () => {
    const numberOfElement =
      data[tenants[currentTenant].imageRef].edges.length - 1;

    if (fullImage !== 0) {
      setFullImage(prev => prev - 1);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[fullImage - 1].node.large
          .fluid
      );
    } else {
      setFullImage(numberOfElement);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[numberOfElement].node.large
          .fluid
      );
    }
  };

  const rightImage = () => {
    const numberOfElement =
      data[tenants[currentTenant].imageRef].edges.length - 1;

    if (fullImage !== numberOfElement) {
      setFullImage(prev => prev + 1);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[fullImage + 1].node.large
          .fluid
      );
    } else {
      setFullImage(0);
      setImageSrc(
        data[tenants[currentTenant].imageRef].edges[0].node.large.fluid
      );
    }
  };

  if (!loaded) return null;
  return (
    <>
      <TitleContainer>
        <PageName id="SectionTitle"> TENANTS </PageName>
      </TitleContainer>
      <FullImageButton
        imageOpen={imageOpen}
        onClick={hideImg}
      ></FullImageButton>
      <FullImageContainer id="fullImageContainer">
        <FullImageWrapper>
          <FullImageBack onClick={hideImg}>BACK</FullImageBack>
          <FullImageCarousel>
            <ChevronWrapper onClick={leftImage}>
              <Chevron src={Left}></Chevron>
            </ChevronWrapper>

            {/*This needs a better default value - just setting it to Erika's first pic for now*/}
            <FullImage
              className="tweenImg"
              fluid={imageSrc || data.erikaWar.edges[0].node.small.fluid}
            ></FullImage>

            <ChevronWrapper onClick={rightImage}>
              <Chevron src={Right}></Chevron>
            </ChevronWrapper>
          </FullImageCarousel>
        </FullImageWrapper>
      </FullImageContainer>
      <TenantInfo
        id="info"
        onClick={e => {
          if (e.target.id === "info" || e.target.id === "infoWrapper") {
            TweenMax.to("#info", 0.75, {
              opacity: 0,
            });
            setTenant(false);
          }
        }}
      >
        {currentTenant !== false && (
          <TenantInfoWrapper id="infoWrapper">
            <BackButton
              onClick={() => {
                TweenMax.to("#info", 0.75, {
                  opacity: 0,
                });
                setTenant(false);
                window.history.pushState("", "", "/tenants");
              }}
              onMouseOver={() => {
                document.body.style.cursor = "pointer";
              }}
              onMouseOut={() => {
                document.body.style.cursor = "default";
              }}
            >
              BACK
            </BackButton>
            <NameHireContainer>
              <Name>{`${tenants[currentTenant].name}`} &ndash; </Name>
              <Hire
                href={`mailto:hire@hxouse.com?subject=Hire ${tenants[currentTenant].name}`}
              >
                HIRE
              </Hire>
            </NameHireContainer>
            <h2> {`${tenants[currentTenant].title}`} </h2>
            <p>{`${tenants[currentTenant].description}`}</p>
            {data[tenants[currentTenant].imageRef].edges.length !== 0 ? (
              <GalleryContainer>
                <StaticGallery
                  setFullImage={setFullImage}
                  data={data}
                  imageRef={tenants[currentTenant].imageRef}
                  changeImage={changeImage}
                />
              </GalleryContainer>
            ) : null}

            {tenants[currentTenant].website !== "" ? (
              <TenantInfoSocial
                hasWebsite={tenants[currentTenant].website !== ""}
              >
                <TenantInfoSocialWrapper
                  href={`http://${tenants[currentTenant].website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  left
                >
                  <h3>Website</h3>
                  <p>{`${tenants[currentTenant].website}`.toUpperCase()}</p>
                </TenantInfoSocialWrapper>
                <TenantInfoSocialWrapper
                  href={`http://www.instagram.com/${tenants[currentTenant].ig}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  right
                >
                  <h3>Instagram</h3>
                  <p>{`@${tenants[currentTenant].ig}`.toUpperCase()}</p>
                </TenantInfoSocialWrapper>
              </TenantInfoSocial>
            ) : (
              <TenantInfoSocial
                hasWebsite={tenants[currentTenant].website !== ""}
              >
                <TenantInfoSocialWrapper
                  href={`http://www.instagram.com/${tenants[currentTenant].ig}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  left
                  right
                >
                  <h3>Instagram</h3>
                  <p>{`@${tenants[currentTenant].ig}`.toUpperCase()}</p>
                </TenantInfoSocialWrapper>
              </TenantInfoSocial>
            )}
          </TenantInfoWrapper>
        )}
      </TenantInfo>
      <Canvas
        style={canvasStyle}
        camera={{
          fov: 80,
          near: 0.1,
          far: 10000,
          position: [0, 0, 0],
        }}
        id="tenantsCanvas"
      >
        <Controls
          deepLinkedTenant={deepLinkedTenant}
          setTenant={setTenant}
          mouse={mouse}
          hasAnimated={hasAnimated}
          changeHasAnimated={changeHasAnimated}
        />
        {positions.map((position, i) => {
          if (currentTenant === false) {
            return (
              <Shape
                key={position}
                position={position}
                image={tenants[i].texture}
                index={i}
                active={false}
                name={tenants[i].name}
                envmap={envmap}
              />
            );
          }
          if (i < 9) {
            return (
              <Shape
                key={position}
                position={position}
                image={tenants[i].texture}
                activeImage={tenants[currentTenant].texture}
                index={currentTenant}
                active
                activeCoords={coordinates[i]}
                name={tenants[i].name}
                envmap={envmap}
              />
            );
          }
          return (
            <Shape
              key={position}
              position={position}
              image={tenants[i].texture}
              index={i}
              active={false}
              name={tenants[i].name}
              envmap={envmap}
            />
          );
        })}
        <Raycaster
          setTenant={setTenant}
          disable={currentTenant !== false}
          setMouse={setMouse}
        />
      </Canvas>
      <Names
        id="namesTenantContainer"
        onClick={e => {
          if (e.target.id === "namesTenantContainer") {
            TweenMax.to("#info", 0.75, {
              opacity: 0,
            });
            setTenant(false);
          }
        }}
      >
        {positions.map((position, i) => {
          return (
            <div id={tenants[i].name} key={i}>
              {tenants[i].name}
            </div>
          );
        })}
      </Names>
    </>
  );
};

export default TenantDesktop;
