import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgContainer = styled.div`
  width: 28%;
  cursor: pointer;

  .image {
    transition: opacity 0.5s ease;

    &:hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 500px) {
    width: 110px;
    height: 110px;
  }

  @media (max-width: 400px) {
    width: 96px;
    height: 96px;
  }

  @media (max-width: 350px) {
    width: 80px;
    height: 80px;
  }
`;

const StaticGallery = ({ changeImage, data, imageRef, setFullImage }) => {
  const imgToDisplay = data[imageRef].edges;

  const showImg = value => {
    setFullImage(value);
    changeImage(imgToDisplay[value].node.large.fluid);
  };

  return (
    <Container>
      {imgToDisplay.map((img, i) => (
        <ImgContainer onClick={() => showImg(i)} key={i}>
          <Img className="image" fluid={img.node.small.fluid} />
        </ImgContainer>
      ))}
    </Container>
  );
};

export default StaticGallery;
